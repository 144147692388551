import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';

import { CompatibleImg } from '../components/Img';
import PageLayout from '../components/PageLayout';
import { RichTextView, device, ContentMain } from '../components/commonStyled';
import Sections from '../components/Sections';

export default ({ data, location }) => {
    const {
        title,
        content,
        featured_media: featured_media_raw,
        seo,
        common_acf: { blocks },
    } = data?.wpPage;

    const { node: featured_media } = featured_media_raw || {};

    return (
        <PageLayout
            breadcrumbs={[
                {
                    title,
                    url: location?.pathname,
                },
            ]}
            title={title}
            seoMeta={seo}
        >
            <Container>
                {featured_media?.localFile && (
                    <ImageWrap>
                        <CompatibleImg image={featured_media.localFile} />
                    </ImageWrap>
                )}
                <RichTextView dangerouslySetInnerHTML={{ __html: content }} />
            </Container>
            <Sections enabledSections={blocks || []} />
        </PageLayout>
    );
};

const Container = styled(ContentMain)`
    display: block;
`;

const ImageWrap = styled.div`
    width: 100%;
    height: 400px;
    border-radius: 33px;
    box-shadow: 0 1px 2px 0 rgba(25, 27, 26, 0.1),
        0 1px 8px 0 rgba(25, 27, 26, 0.08);
    overflow: hidden;

    margin-bottom: 48px;

    @media ${device.laptopSmall} {
        overflow: hidden;
        margin-bottom: 36px;
        margin-right: 0;
    }

    @media ${device.mobileMedium} {
        width: 100%;
        margin-bottom: 24px;
    }
`;

export const pageQuery = graphql`
    query PageById($id: String!) {
        wpPage(id: { eq: $id }) {
            title
            content
            seo {
                ...Seo
            }
            common_acf {
                blocks
            }
            featured_media: featuredImage {
                node {
                    altText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(width: 800)
                        }
                    }
                }
            }
        }
    }
`;
